<template>
  <div class="combobox" :class="{disabled:disabled, readonly: readonly}">
    <div class="select-options">
      <template v-for="(item, i) in selected">
        <span :key="i" class="selected">
          {{item}}
          <button
            v-if="!disabled && !readonly"
            type="button"
            :title="`Deselect ${item}`"
            :aria-label="`Deselect ${item}`"
            @click="removeItem(i)">
            <span class="fe fe-x toggle"></span>
          </button>
        </span>
      </template>
      <input
        v-if="!disabled && !readonly"
        v-model="model"
        type="text"
        autocomplete="off"
        @keypress.enter.prevent="onEnter"
        @keypress.space.prevent="onEnter"
        @keydown.backspace="onDelete"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HCombobox',
  props: {
    value: {
      default: () => [],
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    readonly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      model: '',
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
    },
  },
  methods: {
    onEnter() {
      const value = this.model.trim();
      if (!value) return;

      const isExist = this.selected?.includes(value);

      if (isExist) {
        return;
      }

      this.$emit('input', value);

      this.model = '';
    },
    onDelete(event) {
      if (!this.selected?.length || event.target.value) return;
      this.$emit('delete');
    },
    removeItem(index) {
      this.$emit('remove', index);
    },
  },
};
</script>

<style scoped>
.combobox {
  display: flex;
  width: 100%;
  min-height: 2.375rem;
  padding: .15rem .75rem;
  font-size: 0.9375rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.combobox.disabled {
  background-color: #f8f9fa;
  opacity: 1;
}

.combobox.readonly {
  background-color: transparent;
  border: none;
  padding: 0;
}

.select-options {
  display: flex;
  flex-basis: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  gap: 4px;
}

.select-options .selected {
  border: 0;
  background: #e3f2fd;
  color: #039be5;
  font-size: 12px;
  border-radius: 2px;
  padding: 0.25rem;
  white-space: normal;
}

.select-options .selected:first-child {
  margin-left: 0;
}

.select-options .selected button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  margin-left: 2px;
  padding: 0;
  text-shadow: 0 1px 0 #fff;
  color: #039be5;
}

.select-options input {
  appearance: none;
  border: 1px solid transparent;
  border-left: none;
  outline: none;
  background: none;
  box-shadow: none;
  width: 0;
  max-width: 100%;
  flex-grow: 1;
  z-index: 1;
}
</style>
