<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer>
    <template slot="modal-header-close"><wbr/></template>
    <dimmer :active="isLoading">
      <div class="container img-container">
        <div class="row">
          <div
            v-for="image in value"
            :key="image.id"
            class="col-4 mb-4">
            <a :href="image.url" target="_blank">
              <img
                class="img-thumbnail"
                :src="image.url"
                alt="Purchase Order Invoice">
            </a>
            <button
              type="button"
              class="btn btn-outline-primary btn-download"
              @click="downloadImage(image.id)">
              <i class="fe fe-download"></i>
            </button>
          </div>
        </div>

      </div>
    </dimmer>

  </b-modal>
</template>

<script>
import {images} from '@/services';
import download from '@/mixins/download';
import Dimmer from '../Dimmer.vue';
import modal from '@/mixins/modal';

export default {
  name: 'PoInvoiceModal',
  components: {Dimmer},
  mixins: [download, modal],
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async downloadImage(imageId) {
      this.isLoading = true;
      this.download(await images.downloadInvoice(imageId));
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.img-container {
  height: 20rem;
  overflow: auto;
}

.btn-download {
  width: 100%;
}
</style>
