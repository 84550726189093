<template>
  <dimmer :active="loading">
    <div class="container">
      <div class="card">
        <div class="card-header">
          <div class="card-title">Purchase order detail</div>
          <div class="card-options">
            <router-link :to="route" class="text-blue text-decoration-none d-flex align-items-center">
              <i class="fe fe-arrow-left"/> View other dates
            </router-link>
          </div>
        </div>
        <div class="po-details d-flex justify-content-between flex-wrap p-4">
          <div>
            <h2 class="text-hc-red">{{item.supplier.name}}</h2>
            <div class="d-flex">
              <div class="pr-4">
                <div>
                  <label class="mb-0 text-uppercase text-muted d-block">Delivery date:</label>
                  {{item.delivery_date}}
                </div>
                <div class="mt-2">
                  <label class="mb-0 text-uppercase text-muted d-block">Strike rate:</label>
                  {{item.strike_rate || '-'}}
                </div>
              </div>
              <div class="po-number px-4">
                <div>
                  <label class="mb-0 text-uppercase text-muted d-block">P.O number:</label>
                  <strong>{{item.id}}</strong>
                </div>
                <div class="mt-2">
                  <label class="mb-0 text-uppercase text-muted d-block">Total items:</label>
                  {{item.purchases.length || '0'}}
                </div>
              </div>
              <div class="pl-4">
                <label class="mb-0 text-uppercase text-muted d-block">Status:</label>
                <h-badge
                  v-if="POStatuses[item.status]"
                  :class="POStatuses[item.status].tagClass"
                  class="text-uppercase d-inline-block">
                  {{POStatuses[item.status].value}}
                </h-badge>
              </div>
            </div>
          </div>
          <div>
            <div class="row gutter">
              <div class="col-auto">
                <label class="mb-0 text-uppercase text-muted d-block">Receiving time:</label>
                {{checklist.received_at ? moment(checklist.received_at).format('hh:mm A') : 'N/A'}}
              </div>
              <div class="col-auto">
                <div>
                  <label class="mb-0 text-uppercase text-muted d-block">Vehicle registration:</label>
                  {{checklist.vehicle_registration || 'N/A'}}
                </div>
                <div>
                  <label class="mb-0 text-uppercase text-muted d-block">Vehicle temperature:</label>
                  {{checklist.delivery_vehicle_temperature || 'N/A'}}°C
                </div>
              </div>
              <div class="col-auto">
                <div>
                  <label class="mb-0 text-uppercase text-muted d-block">DM Delivery Number:</label>
                  {{checklist.delivery_number || 'N/A'}}
                </div>
                <div>
                  <label class="mb-0 text-uppercase text-muted d-block">Invoice number:</label>
                  {{checklist.invoice_number || 'N/A'}}
                </div>
                <div>
                  <button
                    :hidden="!poClosed || !item.invoice_images.length"
                    class="btn btn-primary mt-6"
                    @click="showInvoiceModal = true">
                    Preview Invoice
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-outline table-vcenter card-table">
            <thead>
              <th>Article name</th>
              <th>Pack size</th>
              <th v-b-tooltip.hover title="Ordering unit of measure">
                OUM <i class="fe fe-help-circle"/>
              </th>
              <th>Expiry date</th>
              <th>Batch codes</th>
              <th>Decided Quantity</th>
              <th>Received Quantity</th>
              <th>Exception</th>
              <th class="w-1 text-center">Inspected?</th>
            </thead>
            <tbody>
              <tr v-for="article in item.purchases" :key="article.id">
                <td>
                  <a
                    v-if="can(uiPermissions.ARTICLES_VIEW)"
                    :href="`/articles/${article.article.id}`"
                    target="_blank">
                    {{article.article.name}}
                  </a>
                  <span v-else>{{article.article.name}}</span>
                </td>
                <td>
                  {{article.pack_size || 'N/A'}}
                </td>
                <td>{{article.oum.name}}</td>
                <td>{{article.receiving.expiry_date || 'N/A'}}</td>
                <td>
                  <h-combobox
                    v-if="article.receiving.batch_codes"
                    :readonly="true"
                    :value="article.receiving.batch_codes"/>
                  <span v-else>N/A</span>
                </td>
                <td>{{article.decided_qty || 'N/A'}}</td>
                <td>{{article.receiving.received_quantity || 'N/A'}}</td>
                <td>
                  {{article.receiving.has_exception ? article.receiving.comment : 'None'}}
                </td>
                <td class="w-1 text-center">
                  <i
                    class="fe"
                    :class="article.receiving.quality_inspected ? 'fe-check text-success' : 'fe-x text-danger'"/>
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="!item.purchases.length" class="text-center">No items found</div>
        </div>
      </div>

      <po-invoice-modal
        v-if="item.id && showInvoiceModal"
        :show="showInvoiceModal"
        :value="item.invoice_images"
        @close="showInvoiceModal = false"
        @show="value => showInvoiceModal = value"/>
    </div>
  </dimmer>
</template>

<script>

import POStatuses from '@/assets/enums/POStatuses';
import PoInvoiceModal from '@/components/modals/PoInvoiceModal';
import HBadge from '@/components/ui/HBadge';
import HCombobox from '@/components/ui/HCombobox';
import edit from '@/mixins/edit';
import {purchaseOrders} from '@/services';

const DEFAULT_PURCHASES_DATA = {
  received_quantity: null,
  expiry_date: null,
  has_exception: false,
  quality_inspected: false,
  comment: null,
};

const DEFAULT_CHECKLIST_DATA = {
  delivery_number: null,
  delivery_vehicle_temperature: null,
  invoice_number: null,
  received_at: '',
  vehicle_registration: null,
};

export default {
  components: {
    HBadge,
    PoInvoiceModal,
    HCombobox,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      POStatuses,
      showInvoiceModal: false,
      item: {
        delivery_date: '',
        status: '',
        strike_rate: '',
        purchases: [],
        supplier: {},
        inbound_checklist: null,
      },
      with: [
        'inboundChecklist',
        'purchases',
        'supplier',
        'purchases.article',
        'purchases.oum',
        'purchases.receiving',
        'invoiceImages',
      ],
    };
  },
  computed: {
    route() {
      return {
        path: '/purchase-order/reports',
        query: this.$route.query,
      };
    },
    checklist() {
      return !this.item.inbound_checklist ? DEFAULT_CHECKLIST_DATA : this.item.inbound_checklist;
    },
    poClosed() {
      return this.item.status === 'closed';
    },
  },
  methods: {
    fetchData(id) {
      const params = {
        with: this.with.join(','),
      };

      return purchaseOrders.getById(id, {params});
    },
    transformData(result) {
      result.item = Object.assign(result.item, {
        purchases: result.item.purchases.map(purchase => {
          // use default values
          if (!purchase.receiving) {
            return Object.assign(purchase, {receiving: {...DEFAULT_PURCHASES_DATA}});
          }
          purchase.receiving.has_exception = !!purchase.receiving.has_exception;
          purchase.receiving.quality_inspected = !!purchase.receiving.quality_inspected;
          return purchase;
        }),
      });

      return result;
    },
  },
};
</script>

<style scoped>
.po-number {
  border-left: 1px solid #757575;
  border-right: 1px solid #757575;
}

.po-details {
  background: #f8f8f8;
  gap: 0.5rem;
}

.po-item {
  border: 1px solid #dadada;
  border-radius: 8px;
}

label {
  color: #757575;
  font-weight: 600;
}

.article-name {
  margin-bottom: 1rem;
  max-width: 55ch;
  font-size: 1.1rem;
  font-weight: 600;
}

.article-left,
.article-right {
  padding: 1rem 1.5rem;
}

.article-left {
  flex-grow: 1;
  border-right: 1px solid #dadada;
}
</style>
